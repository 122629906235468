<template>
<div>
  <v-card elevation="0">
      <v-card-text>
        <v-row>
          <v-col cols="1" class="pb-0">Activity</v-col>
          <v-col cols="3" class="pb-0">
            <v-autocomplete v-model="activity" :items="activities" item-value="_id" item-text="name" hide-details="auto" dense outlined class="compact-form" return-object></v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="py-3">
          <v-col cols="1">Date From</v-col>
          <v-col cols="3">
            <v-menu v-model="dateFromMenu" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="date_from" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" hide-details="auto" dense outlined class="compact-form"></v-text-field>
            </template>
            <v-date-picker v-model="date_from"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="1">Date To</v-col>
          <v-col cols="3">
            <v-menu v-model="dateToMenu" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="date_to" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" hide-details="auto" dense outlined class="compact-form"></v-text-field>
            </template>
            <v-date-picker v-model="date_to"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="2">
            <v-btn depressed color="primary" @click="runReport">Run</v-btn>
          </v-col>
        </v-row>
        <v-card outlined>
          <v-simple-table fixed-header height="350px" id="tableXls" ref="tableXls">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">No</th>
                  <th class="text-left">No Order</th>
                  <th class="text-left">Tgl Activity</th>
                  <th class="text-left">Customer</th>
                  <th class="text-left">Deskripsi</th>
                  <th class="text-left">Onthespot</th>
                  <th class="text-left">Qty</th>
                  <th class="text-left">Unit Sales</th>
                  <th class="text-left">Total Sales</th>
                  <template v-if="activity">
                  <th class="text-left">Cost {{activity.cost_code_1}}</th>
                  <th class="text-left">Cost {{activity.cost_code_2}}</th>
                  <th class="text-left">Cost {{activity.cost_code_3}}</th>
                  <th class="text-left">GrossProfit</th>
                  </template>
                </tr>
              </thead>
              <tbody>
                <template v-for="(doc, idx) in docs" >
                  <tr :key="idx">
                      <td>{{idx+1}}</td>
                      <td>{{doc.no_order}}</td>
                      <td>{{doc.date}}</td>
                      <td>{{doc.user.name}}</td>
                      <td>{{doc.selection.name}}</td>
                      <td>No</td>
                      <td>{{doc.qty_order}}</td>
                      <td>{{doc.unit_price | numbers}}</td>
                      <td>{{doc.sub_total | numbers}}</td>
                      <td>{{Number(doc.qty_order) * Number(doc.selection.costs.cost_1|0) | numbers}}</td>
                      <td>{{Number(doc.qty_order) * Number(doc.selection.costs.cost_2|0) | numbers}}</td>
                      <td>{{Number(doc.qty_order) * Number(doc.selection.costs.cost_3|0) | numbers}}</td>
                      <td>{{calculateGP(doc) | numbers}}</td>
                  </tr>
                  <tr v-for="(opt, idx2) in doc.options" :key="idx2">
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>{{opt.name}}</td>
                      <td>{{(opt.added)?'Yes':'No'}}</td>
                      <td>{{opt.qty_option}}</td>
                      <td>{{opt.price | numbers}}</td>
                      <td>{{Number(opt.qty_option) * Number(opt.price) | numbers}}</td>
                      <td>{{Number(opt.qty_option) * Number((opt.cost|0)) | numbers}}</td>
                      <td>{{Number(opt.qty_option) * Number((opt.cost_2|0)) | numbers}}</td>
                      <td>{{Number(opt.qty_option) * Number((opt.cost_3|0)) | numbers}}</td>
                      <td>{{calculateGPOption(opt) | numbers}}</td>
                  </tr>
                </template>
              </tbody>
              <tfoot>
                <tr>
                  <th class="text-center" colspan="8">TOTAL</th>
                  <th class="text-left">{{calculateTotalSales | numbers}}</th>
                  <th class="text-left"> {{calculateTotalCost1 | numbers}} </th>
                  <th class="text-left"> {{calculateTotalCost2 | numbers}} </th>
                  <th class="text-left"> {{calculateTotalCost3 | numbers}} </th>
                  <th class="text-left">{{calculateTotalGP | numbers}} </th>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-btn class=" white--text" color="green" depressed @click="export2xls">Export to XLS</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
  </v-card>
  <v-overlay :value="onProgress" style="z-index:999">
      <v-progress-circular :size="50" color="amber" indeterminate></v-progress-circular>
  </v-overlay>  

</div>
</template>

<script>
import moment from 'moment'
import { utils, writeFileXLSX } from 'xlsx';

  // const short = require('short-uuid');
  export default {
    data: () => ({
      docs: [],
      date_from: null,
      dateFromMenu: false,
      date_to: null,
      dateToMenu: false,
      acDetails: null,
      activities: null,
      activity: null,
      onProgress: false
    }),

    computed: {
      calculateTotalSales() {
        let total = 0
        this.docs.forEach(el => {
          total += el.sub_total
          el.options.forEach(opt => {
            total += (Number(opt.qty_option) * Number(opt.price|0))
          });
        });
        return total
      },

      calculateTotalCost1() {
        let total = 0
        this.docs.forEach(el => {
          total += (Number(el.qty_order) * Number(el.selection.costs.cost_1|0))
          el.options.forEach(opt => {
            total += (Number(opt.qty_option) * Number((opt.cost|0)))
          });
        });
        return total
      },

      calculateTotalCost2() {
        let total = 0
        this.docs.forEach(el => {
          total += (Number(el.qty_order) * Number(el.selection.costs.cost_2|0))
          el.options.forEach(opt => {
            total += (Number(opt.qty_option) * Number((opt.cost_2|0)))
          });
        });
        return total
      },

      calculateTotalCost3() {
        let total = 0
        this.docs.forEach(el => {
          total += (Number(el.qty_order) * Number(el.selection.costs.cost_3|0))
          el.options.forEach(opt => {
            total += (Number(opt.qty_option) * Number((opt.cost_3|0)))
          });
        });
        return total
      },

      calculateTotalGP() {
        return this.calculateTotalSales - (this.calculateTotalCost1+this.calculateTotalCost2+this.calculateTotalCost3)
      },

    },

    watch: {

    },

    async created() {
        this.activities = await this.$store.dispatch('reports/getPostObjs', {purl:'reports/getallactivities', pload:{}})
    },

    filters: {
        moment: function (date) {
            if (!date || date=='undefined') return ''
            return moment(date).format('D-MMM-YYYY HH:mm:ss');
        },
        numbers: function (number) {
            return Number(number).toLocaleString()
        }

    },

    methods: {
      calculateGPOption(opt) {
        const revenue = Number(opt.qty_option) * Number(opt.price)
        let cost = 0
        cost += (Number(opt.qty_option) * Number((opt.cost|0)))
        cost += (Number(opt.qty_option) * Number((opt.cost_2|0)))
        cost += (Number(opt.qty_option) * Number((opt.cost_3|0)))
        return revenue - cost
      },
      calculateGP(obj) {
        const revenue = Number(obj.sub_total)
        let cost = 0
        cost += (Number(obj.qty_order) * Number(obj.selection.costs.cost_1|0))
        cost += (Number(obj.qty_order) * Number(obj.selection.costs.cost_2|0))
        cost += (Number(obj.qty_order) * Number(obj.selection.costs.cost_3|0))
        return revenue - cost
      },
      async runReport () {
        const payload = {
          ac_id: this.activity._id,
          date_from: this.date_from,
          date_to: this.date_to
        }
        this.onProgress = true
        this.docs = await this.$store.dispatch('reports/getPostObjs', {purl:'reports/getdailycost', pload:payload})
        this.onProgress = false
        console.log(this.docs)
      },

      export2xls() {
        const tableDom = document.getElementById('tableXls').childNodes[0].childNodes[0]
        console.log(tableDom)
          const wb = utils.table_to_book(tableDom)
          writeFileXLSX(wb, "sales-cost-revenue.xlsx")

      },
      
    },
  }
</script>